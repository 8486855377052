import React, { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import SecondaryButton from '../Buttons/SecondaryButton';
import moment from 'moment';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const calculateGrowth = (value, rate) => {
  return value * (1 + rate);
};

const formatSeriesData = ({ forecastData, dataType, extendedData }) => {
  if (forecastData && forecastData.length) {
    const formattedForecast = forecastData.map((item) => {
      let timestamp = new Date(item.timestamp);
      let value = dataType === 'rate' ? item.value : parseInt(item.value);
      return [timestamp, value];
    });

    if (extendedData) {
      let lastEntry = formattedForecast[formattedForecast.length - 1];
      let lastValue = lastEntry[1];
      let lastDate = new Date(lastEntry[0]);

      for (let year = 1; year <= 15; year++) {
        let nextYear = new Date(lastDate.setFullYear(lastDate.getFullYear() + 1));
        lastValue = calculateGrowth(lastValue, 0.025);
        let resultValue = dataType === 'rate' ? lastValue : parseInt(lastValue);
        formattedForecast.push([nextYear, resultValue]);
      }
    }

    return formattedForecast;
  } else {
    return [];
  }
};

const ItemVsIndexChart = (props) => {
  const forecastDataA = props.itemGraphSeriesData;
  const forecastDataB = props.benchmarkGraphSeriesData;
  const forecastDataC = props.benchmark2GraphSeriesData;
  const dataType = props?.dataType || '';

  const extendedData = props.extendedData;

  const [series, setSeries] = useState([]);
  const [yaxisConfig, setYaxisConfig] = useState([]);
  const [secondAxisActive, setSecondAxisActive] = useState(false);
  const [aSeriesActive, setASeriesActive] = useState(true);
  const [bSeriesActive, setBSeriesActive] = useState(true);
  const [cSeriesActive, setCSeriesActive] = useState(true);
  const [dotSize, setDotSize] = useState([0.1, 0.1, 0.1, 0.1]);
  const [showLabels, setShowLabels] = useState(false);
  const [chartInstance, setChartInstance] = useState(null);

  const csvSeriesLength = forecastDataA?.length > forecastDataB?.length ? forecastDataA?.length : forecastDataB?.length;
  const csvArray = [['category', props.address, props.index]];
  for (let i = 0; i < csvSeriesLength; i++) {
    const date =
      forecastDataA?.length > forecastDataB?.length ? forecastDataA[i].timestamp : forecastDataB[i].timestamp;
    csvArray.push([date, forecastDataA[i]?.value, forecastDataB[i]?.value]);
  }
  const csvContent = 'data:text/csv;charset=utf-8,' + csvArray.map((e) => e.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);

  const labelsFormatter = (val) => {
    return val === undefined || val === null
      ? null
      : dataType === 'rate'
        ? numeral(val).format('0.00%')
        : numeral(parseInt(val.toString())).format();
  };

  const updateAxisConfig = useCallback(
    (secondAxisActive) => {
      const leftAxisMin = Math.min(
        ...[
          aSeriesActive && forecastDataA?.length ? Math.min(...forecastDataA.map((item) => item?.value)) : 0,
          bSeriesActive && !secondAxisActive && forecastDataB?.length
            ? Math.min(...forecastDataB.map((item) => item?.value))
            : null,
          cSeriesActive && !secondAxisActive && forecastDataC?.length
            ? Math.min(...forecastDataC.map((item) => item?.value))
            : null,
        ].filter((item) => item !== null),
      );

      const leftAxisMax = Math.max(
        ...[
          aSeriesActive && forecastDataA?.length ? Math.max(...forecastDataA.map((item) => item?.value)) : null,
          bSeriesActive && !secondAxisActive && forecastDataB?.length
            ? Math.max(...forecastDataB.map((item) => item?.value))
            : null,
          cSeriesActive && !secondAxisActive && forecastDataC?.length
            ? Math.max(...forecastDataC.map((item) => item?.value))
            : null,
        ].filter((item) => item !== null),
      );

      const diffRatioLeft = (leftAxisMax - leftAxisMin) * 0.05;

      const yAxisConfigArray = [
        {
          showAlways: true,
          min: leftAxisMin - diffRatioLeft,
          max: Math.max(leftAxisMax, extendedData ? leftAxisMax * 1.4 : leftAxisMax) + diffRatioLeft,
          labels: {
            formatter: labelsFormatter,
          },
        },
      ];

      if (secondAxisActive) {
        let rightAxisMin = Math.min(
          ...[
            bSeriesActive && forecastDataB?.length ? Math.min(...forecastDataB.map((item) => item?.value)) : null,
            cSeriesActive && forecastDataC?.length ? Math.min(...forecastDataC.map((item) => item?.value)) : null,
          ].filter((item) => item !== null),
        );

        let rightAxisMax = Math.max(
          ...[
            bSeriesActive && forecastDataB?.length ? Math.max(...forecastDataB.map((item) => item?.value)) : null,
            cSeriesActive && forecastDataC?.length ? Math.max(...forecastDataC.map((item) => item?.value)) : null,
          ].filter((item) => item !== null),
        );

        if ((!bSeriesActive && forecastDataC?.length && !cSeriesActive) || (!bSeriesActive && !forecastDataC?.length)) {
          rightAxisMin = Math.min(
            ...[
              forecastDataB?.length ? Math.min(...forecastDataB.map((item) => item?.value)) : null,
              forecastDataC?.length ? Math.min(...forecastDataC.map((item) => item?.value)) : null,
            ].filter((item) => item !== null),
          );

          rightAxisMax = Math.max(
            ...[
              forecastDataB?.length ? Math.max(...forecastDataB.map((item) => item?.value)) : null,
              forecastDataC?.length ? Math.max(...forecastDataC.map((item) => item?.value)) : null,
            ].filter((item) => item !== null),
          );
        }

        const diffRatioRight = (rightAxisMax - rightAxisMin) * 0.05;

        if (props.lastTransactionData) {
          yAxisConfigArray.unshift({
            show: false,
            showAlways: true,
            min: leftAxisMin - diffRatioLeft,
            max: Math.max(leftAxisMax, extendedData ? leftAxisMax * 1.4 : leftAxisMax) + diffRatioLeft,
            labels: {
              formatter: labelsFormatter,
            },
          });
        }

        yAxisConfigArray.push({
          opposite: true,
          showAlways: true,
          min: rightAxisMin - diffRatioRight,
          max: Math.max(rightAxisMax, extendedData ? rightAxisMax * 1.4 : rightAxisMax) + diffRatioRight,
          labels: {
            formatter: labelsFormatter,
          },
        });

        forecastDataC?.length &&
          yAxisConfigArray.push({
            show: false,
            showAlways: true,
            opposite: true,
            min: rightAxisMin - diffRatioRight,
            max: Math.max(rightAxisMax, extendedData ? rightAxisMax * 1.4 : rightAxisMax) + diffRatioRight,
            labels: {
              formatter: labelsFormatter,
            },
          });
      }

      setYaxisConfig(yAxisConfigArray);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      aSeriesActive,
      bSeriesActive,
      cSeriesActive,
      forecastDataA,
      forecastDataB,
      forecastDataC,
      props.lastTransactionData,
    ],
  );

  useEffect(() => {
    setTimeout(() => {
      props.updateCSVEncodedURI?.(encodedUri);
    }, 500);
  }, [encodedUri]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setASeriesActive(true);
  }, [forecastDataA]);

  useEffect(() => {
    setBSeriesActive(true);
  }, [forecastDataB]);

  useEffect(() => {
    setCSeriesActive(true);
  }, [forecastDataC]);

  useEffect(() => {
    const seriesData = [
      {
        name: secondAxisActive ? props.address + ' (left axis)' : props.address,
        type: 'area',
        data: formatSeriesData({ forecastData: forecastDataA, dataType, extendedData }),
      },
    ];

    props.index &&
      seriesData.push({
        name: secondAxisActive ? props.index + ' (right axis)' : props.index,
        type: 'area',
        data: formatSeriesData({ forecastData: forecastDataB, dataType, extendedData }),
      });

    props.index2 &&
      seriesData.push({
        name: secondAxisActive ? props.index2 + ' (right axis)' : props.index2,
        type: 'area',
        data: formatSeriesData({ forecastData: forecastDataC, dataType, extendedData }),
      });

    if (props.lastTransactionData && props.lastTransactionData.date) {
      setDotSize([6, 0.1, 0.1, 0.1]);
      let formattedDate = moment(props.lastTransactionData.date, ['YYYY-MM-DD', 'MM/DD/YYYY'], true);
      const resultDate = new Date(formattedDate);

      seriesData.unshift({
        name: 'Last Transaction',
        type: 'area',
        data: forecastDataA.map((item) => {
          let timestamp = new Date(item.timestamp);
          let value = moment(resultDate).isSame(item.timestamp, 'month')
            ? parseInt(props.lastTransactionData.price)
            : '';
          return [timestamp, value];
        }),
      });
    }
    setSeries(seriesData);
    updateAxisConfig(secondAxisActive);
    setShowLabels(true);
  }, [
    forecastDataC,
    forecastDataB,
    forecastDataA,
    props.address,
    props.index,
    props.index2,
    props.lastTransactionData,
    dataType,
    secondAxisActive,
    updateAxisConfig,
    extendedData,
  ]);

  useEffect(() => {
    if (chartInstance) {
      setTimeout(() => {
        chartInstance.dataURI().then(({ imgURI }) => {
          props.renderImage?.(imgURI);
        });
      }, 500);
    }
  }, [chartInstance, props, props.renderImage, secondAxisActive, aSeriesActive, bSeriesActive, cSeriesActive]);

  const options = {
    chart: {
      id: 'price-chart',
      height: 350,
      toolbar: {
        show: true,
      },

      animations: {
        enabled: false,
      },
      events: {
        legendClick: function (chartContext, seriesIndex, config) {
          if (seriesIndex === 0) {
            setASeriesActive((current) => !current);
          } else if (seriesIndex === 1) {
            setBSeriesActive((current) => !current);
          } else if (seriesIndex === 2) {
            setCSeriesActive((current) => !current);
          }
        },
        mounted: function (chart) {
          setChartInstance(chart);
        },
      },
    },
    tooltip: {
      shared: true,
      enabled: true,
      y: {
        formatter: function (value) {
          if (value) {
            return dataType === 'rate' ? numeral(value).format('0.00%') : numeral(parseInt(value.toString())).format();
          }
        },
      },
    },
    forecastDataPoints: {
      count: 23,
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      colors: props.lastTransactionData
        ? ['#3571E5', '#3571E5', '#ff4560', '#705fd3']
        : ['#3571E5', '#ff4560', '#705fd3'],
      strokeColors: props.lastTransactionData
        ? ['#3571E5', '#3571E5', '#ff4560', '#705fd3']
        : ['#3571E5', '#ff4560', '#705fd3'],
      strokeOpacity: 1,
      fillOpacity: 1,
      size: dotSize,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      tickAmount: extendedData ? 22 : 30,
      labels: {
        formatter: function (val, timestamp) {
          return extendedData ? moment(timestamp).format('YYYY') : moment(timestamp).format('MMM YYYY');
        },
        rotate: -90,
        rotateAlways: true,
        show: showLabels,
      },
      lines: {
        show: false,
      },
    },
    yaxis: yaxisConfig,
    colors: props.lastTransactionData
      ? ['#3571E5', '#3571E5', '#ff4560', '#705fd3']
      : ['#3571E5', '#ff4560', '#705fd3'],
  };

  return (
    <>
      <div id="chart2" className={`bar-chart pb-3 ${props?.lastTransactionData ? 'chart-with-transaction' : ''}`}>
        <ReactApexChart options={options} type="area" series={series} height={350} />
      </div>
      {props.secondaryAxisButtonEnabled && (
        <div className="flex justify-end px-2">
          <SecondaryButton
            className="mx-1 mb-3 -m-10 px-3 w-auto"
            styles={{ zIndex: '2' }}
            onClick={() => setSecondAxisActive((secondAxisActive) => !secondAxisActive)}
          >
            {secondAxisActive ? 'Remove Second Axis' : 'Add Second Axis'}
          </SecondaryButton>
        </div>
      )}
    </>
  );
};

export default ItemVsIndexChart;
